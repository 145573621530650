import { auth } from '../auth/firebase';
import { isWebview } from '../components/app/helpers';
import { handleDates } from '../interceptors/DatesInterceptor';
import { apiCall } from '../utils/api.util';

// TODO REVIEW THIS LARGE METHOD, we will call this metod updateUser(userPayload)

export const getMyUser = async () => {
  return (await apiCall.get('/me')).data.user;
};

export const createUser = async (body) => {
  return (await apiCall.post('/me', { ...body })).data;
};

export const updateUsersProfile = async (body) => {
  return (await apiCall.patch('/me', body)).data;
};

export const updateUsersLang = async ({ lang }) => {
  return (await apiCall.patch('/me/lang', { lang })).data;
};

export const getSessions = async () => {
  return (await apiCall.get('/sessions')).data;
};

export const getSessionByKey = async (key) => {
  return (await apiCall.get(`/sessions/key/${key}`)).data;
};

export const completeCatalogSession = async (completeSessionPayload) => {
  return (await apiCall.patch('/me', completeSessionPayload)).data;
};

export const completeSession = async (completeSessionPayload) => {
  return (await apiCall.patch('/sessions/complete', completeSessionPayload)).data;
};

export const identifyDevice = async () => {
  // If user is not logged in, do nothing.
  if (!auth.currentUser) return;

  let hasDownloadedMobileApp = false;

  if (isWebview() === true) {
    hasDownloadedMobileApp = true;
  }

  apiCall.post('/me/device', { hasDownloadedMobileApp: hasDownloadedMobileApp });
};

export const completePill = async (recoveryPlanIndex, sessionIndex) => {
  return (await apiCall.patch('/me/completePill', { recoveryPlanIndex, sessionIndex })).data;
};

export const archiveRecoveryPlan = async (recoveryPlanIndex) => {
  return (await apiCall.patch('/me/archiveRecoveryPlan', { recoveryPlanIndex })).data;
};

export const getMyLeaderboard = async () => {
  return (await apiCall.get('/users/leaderboard')).data;
};

export const getMyChallenges = async () => {
  return (await apiCall.get('/me/challenges')).data;
};

export const getProgressCalendar = async () => {
  const datesInterceptor = apiCall.interceptors.response.use((res) => {
    handleDates(res.data);
    return res;
  });

  return (
    await apiCall.get('/me/progress/calendar').finally(() => {
      apiCall.interceptors.response.eject(datesInterceptor);
    })
  ).data;
};
