import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../auth/firebase';

/**
 * Logs in an existing external user or signs up a new external user.
 * @param {Object} user - The user object containing the user's id, name, and email.
 * @param {string} user.id - The user's id.
 * @param {string} user.name - The user's name.
 * @param {string} user.email - The user's email.
 * @returns {Promise<void>} - A promise that resolves when the login or sign up process is complete.
 */
export const loginOrSignUpExternalUser = async ({ id, name, email }) => {
  const password = `000000${id}`;
  const signInMethodsForEmail = await fetchSignInMethodsForEmail(auth, email);
  const emailExist = signInMethodsForEmail.length !== 0;

  if (emailExist === true) {
    await signInWithEmailAndPassword(auth, email, password);
  }
  if (emailExist === false) {
    await createUserWithEmailAndPassword(auth, email, password);
    await window?.updateProfile(auth.currentUser, { displayName: name });
  }
};
