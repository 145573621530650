import { createContext, useRef } from 'react';

export const MediaContext = createContext();

export function MediaProvider({ children }) {
  const audioRef = useRef(undefined);
  const videoRef = useRef(undefined);

  const value = { audioRef, videoRef };

  return (
    <>
      <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
    </>
  );
}
