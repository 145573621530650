'use client';

import { VideoProvider } from 'src/context/VideoContext';
import { MediaProvider } from 'src/context/MediaContext';
import { FeatureFlagProvider } from 'src/context/FeatureFlagContext';
import { AuthProvider } from 'src/context/AuthContext';
import { DevTools, Tolgee, TolgeeProvider } from '@tolgee/react';
import { FormatIcu } from '@tolgee/format-icu';
import en from 'src/lib/i18n/locales/en.json';
import es from 'src/lib/i18n/locales/es.json';
import eu from 'src/lib/i18n/locales/eu.json';
import enExercises from 'src/lib/i18n/exercises/en.json';
import esExercises from 'src/lib/i18n/exercises/es.json';
import euExercises from 'src/lib/i18n/exercises/eu.json';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from 'src/components/ErrorHandler';

interface MainProviderProps {
  children: React.ReactNode;
}

export const MainProvider = ({ children }: MainProviderProps) => {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatIcu())
    .init({
      language: 'es',

      // for development
      apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
      apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
      fallbackNs: 'exercises',

      // for production
      staticData: {
        en,
        es,
        eu,
        'en:exercises': enExercises,
        'es:exercises': esExercises,
        'eu:exercises': euExercises,
      },
    });
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <TolgeeProvider tolgee={tolgee}>
        <BrowserRouter>
          <VideoProvider>
            <AuthProvider>
              <MediaProvider>
                <FeatureFlagProvider>{children}</FeatureFlagProvider>
              </MediaProvider>
            </AuthProvider>
          </VideoProvider>
        </BrowserRouter>
      </TolgeeProvider>
    </ErrorBoundary>
  );
};
