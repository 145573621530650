import { createContext, useState, ReactNode } from 'react';
import { FeatureFlag } from '../model/FeatureFlag';

type FeatureFlagContextType = {
  featureFlags: FeatureFlag[];
  setFeatureFlags: (featureFlags: FeatureFlag[]) => void;
};
export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: [],
  setFeatureFlags: () => {},
});

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, setFeatureFlags }}>{children}</FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContext;
