import axios from 'axios';
import { loginOrSignUpExternalUser } from './MainExternalIntegrationService';

export const AXA_PARTNER_CODE = 'axa';

/**
 * Checks if the integration is an AXA implementation.
 * @param {URLSearchParams} parameters - The URL search parameters.
 * @returns {boolean} - Returns true if the integration is an AXA implementation and has a valid access token, otherwise returns false.
 */
export const isAxaImplementation = (parameters) => {
  const accessToken = getAxaAccessToken();
  const isPartnerAxa =
    parameters.get('partnerCode') === AXA_PARTNER_CODE ||
    parameters.get('partnerCode') === `${AXA_PARTNER_CODE}?` ||
    parameters.get('partner') === AXA_PARTNER_CODE ||
    parameters.get('partner') === `${AXA_PARTNER_CODE}?`;

  return isPartnerAxa && accessToken !== undefined;
};

/**
 * Logs in or signs up an AXA user.
 * @returns {Promise<void>} A Promise that resolves when the login or sign up process is complete.
 */
export const loginOrSignUpAxaUser = async () => {
  const accessToken = getAxaAccessToken();
  const { id, email, name } =
    process.env.NODE_ENV === 'production' && window.location.host === 'app.fisify.com'
      ? await getAxaUser(accessToken)
      : await mockGetAxaUser();

  loginOrSignUpExternalUser({ id, email, name });
};

/**
 * Retrieves the AXA access token from the current window location.
 * @returns {string} The AXA access token.
 */
const getAxaAccessToken = () => window.location.href.split('#access_token=')[1];

/**
 * Retrieves the AXA user data using the provided access token.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise<Object>} - A promise that resolves to the AXA user data.
 */
const getAxaUser = async (accessToken) => {
  const url = 'https://axahealthkeeper.com/api/v1/basic-user';

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (await axios.get(url, config)).data;
};

/**
 * Mock function to get an AXA user for testing purposes.
 * @returns {Promise<Object>} The AXA user object.
 */
const mockGetAxaUser = async () => ({
  id: 'ba01ad20-9dfe-4146-adbc-3fcb979937f0',
  email: 'testing@axa.com',
  name: 'Sr. Testing',
});
