import { EMAIL_PARTNER_WHITELIST } from '../constants';

export function dayDifferenceBetween(a, b) {
  const years = Math.abs(a.getFullYear() - b.getFullYear());
  const months = Math.abs(a.getMonth() - b.getMonth());
  const days = Math.abs(a.getDate() - b.getDate());
  return years > 0 || months > 0 || days > 0;
}

export function isEmailPartner(partner) {
  return EMAIL_PARTNER_WHITELIST.includes(partner?.toLowerCase());
}

/**
 * @param recoveryPlan
 * @returns The completed session count in a recoveryPlan
 */
export function getCompletedSessionCountOf(recoveryPlan) {
  let count = 0;
  for (const session of recoveryPlan.sessions) {
    if (session.completed === true) count++;
  }
  return count;
}

/**
 * Get the session which user has to realize today
 */
export function getCurrentSessionOf(user) {
  const recoveryPlanIndex = parseInt(localStorage.getItem('currentRecoveryPlanIndex'));
  const recoveryPlan = user.recoveryPlans[recoveryPlanIndex];
  const day = getSessionDay(recoveryPlan);
  return recoveryPlan.sessions[day];
}

/**
 * Get the recovery plan index which is stored in localStorage.
 */
export function getCurrentRecoveryPlanIndex() {
  return parseInt(localStorage.getItem('currentRecoveryPlanIndex'));
}

/**
 * @returns The first not completed session index, but only if the difference between today and last completed exercise is more
 * than ONE DAY.
 */
export function getSessionDay(recoveryPlan) {
  const uncompletedSessionIndex = recoveryPlan.sessions.findIndex((x) => !x.completed);

  if (uncompletedSessionIndex === -1) {
    return null;
  } else if (uncompletedSessionIndex === 0) {
    return 0;
  } else {
    const now = new Date();
    const lastSessionDate = new Date(recoveryPlan.sessions[uncompletedSessionIndex - 1].completedDate);
    if (dayDifferenceBetween(now, lastSessionDate) >= 1) {
      return uncompletedSessionIndex;
    } else {
      return uncompletedSessionIndex - 1;
    }
  }
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

/**
 * @param {string} str
 * @returns
 */
export function parseBoolean(str) {
  return new Map([
    [String(true), true],
    [String(false), false],
  ]).get(str?.toLowerCase());
}

export function mapValuesToArray(map) {
  return Array.from(map.values());
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function countWords(str) {
  return str.split(/\s+/).filter(Boolean).length;
}
