import { useState, createContext } from 'react';
import { addUserFromFirebase, auth, getUserFromFirebase } from '../auth/firebase';
import Loading from '../components/Loading';
import { createUser, getMyUser } from '../services/UserService';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  deleteUser,
} from '@firebase/auth';
import { useErrorHandler } from 'react-error-boundary';
import { AXA_PARTNER_CODE, isAxaImplementation, loginOrSignUpAxaUser } from '../services/integrations/AxaService';
import { isEmailPartner } from '../utils/utils';
import {
  VITALY_PARTNER_CODE,
  handleVitalyError,
  isVitalyImplementation,
  vitalyMessagesListener,
} from '../services/integrations/VitalyService';
import Cookies from 'js-cookie';

const DEBUG_MODE = true;

export const AuthContext = createContext();

export const registerOrLoginPartner = async (email, password, user, partner, bypassPartner) => {
  const signInMethodsForEmail = await fetchSignInMethodsForEmail(auth, email);
  const userFromFirebase = await getUserFromFirebase(user);

  const userFromFirebaseExist = Boolean(userFromFirebase);
  const emailExist = signInMethodsForEmail.length !== 0;

  if (userFromFirebaseExist && isEmailPartner(partner)) {
    // Login with email from firestore
    return signInWithEmailAndPassword(auth, userFromFirebase?.email, password);
  } else if (emailExist) {
    // Login with email from query params as always
    return signInWithEmailAndPassword(auth, email, password);
  } else if (!isEmailPartner(partner) || bypassPartner) {
    // Create user with email from query params or incase of bypassPartner email comes from input
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return addUserFromFirebase({
      user,
      email,
      uid: userCredential.user.uid,
      partner,
    });
  }
};

export function AuthProvider({ children }) {
  const handleError = useErrorHandler();

  const [state, setState] = useState(undefined);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isPointsModalVisible, setIsPointsModalVisible] = useState(false);
  const [langIsLoading, setLangIsLoading] = useState(true);

  const parameters = new URLSearchParams(location.search);

  const partnerMode =
    (parameters.has('partnerCode') && parameters.has('user')) || (parameters.has('partner') && parameters.has('user'));

  /**
   * Integration with clients
   */
  const isAxaIntegration = isAxaImplementation(parameters);
  const isVitalyIntegration = isVitalyImplementation();
  const standardMode = !isAxaIntegration && !isVitalyIntegration;

  useState(() => {
    if (standardMode) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user !== null) {
          const userFromBackend = await getMyUser();
          setState({ backendUser: userFromBackend, firebaseUser: user });
          Cookies.set('jwt', await user.getIdToken());
          DEBUG_MODE &&
            console.log('AuthContext: ', {
              userFromBackend,
              firebaseUser: user,
            });
          return unsubscribe();
        }

        setState({ backendUser: null, firebaseUser: user });
        Cookies.set('jwt', await user.getIdToken());
        return unsubscribe();
      });
    }
  }, []);

  /**
   * Axa integration
   *
   * @testing https://localhost:3001/home?partner=axa?&embed=true#access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TmFtZSI6IlBheiIsInVzZXJfbmFtZSI6IjI5NWEwMmFiOGIxYzc5Yzk5ZDEwMmEzZTAxMGJiZjYxMTBjYzc1MWZlNzY5NmU0YjJlMmRjNTcyYWUxMjYyMTg1N2NjYzVlODU2OTk2YTY3OTU1ZGI2NjUwMDk5NDkyNmM4YTZjMWQ0NmQ3YjVlODkwMWM5Mjk1NmY1ZjBlN2JiIiwiY29udGV4dElkIjoiZXMiLCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXSwiZW5hYmxlZCI6dHJ1ZSwiY2xpZW50X2lkIjoicmFnbmFyb2siLCJvcmdhbml6YXRpb25JZCI6MSwib3JnYW5pemF0aW9uQ29kZSI6ImRlZmF1bHQiLCJzY29wZSI6WyJkX3VzZXIiLCJyX2JlbmVmaXQiLCJyX3RjIiwicl91c2VyIiwid191c2VyIl0sIm5hbWUiOiJFZHVhcmRvIiwiaWQiOjQzMzYxLCJleHAiOjE2NTgzNDc5ODEsImp0aSI6IjY5MWUwMTJiLTYxMGYtNGQ1MC05YjgxLTYxZmZmMTk1MThkZiJ9.c6niNKkYMsItfZcdwMNy3Ohr3qIZ1CsRdfbuZCLlmwmjumFplPo63Y1KS43JFwy6UmMMFkH_6fBAyyMtuPRjW79VjU9HKC9hnvpnHSmo9OStai7t9zH_gig6lVRTuTZKP2jiEKi6doP_VPJ7u_eSnoXigy2W0LmGnpwiDYfMLrYu3aE8kJSz67kZe23AHCglZCdgEsPs2zmNMl_aniddEmbLKR7CxSKSyP9c6sErpJyCvOrMbANVNbujGHB2P30ZeRiz2AO_SPAgtUicjw36tYx9ZUFY2rKuO5d4jLteoY9WAC9nxpyX1dcNWrjTnl4Di8g9bzPTLKjXhHmzJ-r_RQ
   */
  useState(() => {
    if (isAxaIntegration) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user !== null) {
          try {
            //! this is because they are sending axa? as partner, just for testing!
            // const partner = parameters.get("partner");
            const userFromBackend = await createUser({ partnerCode: AXA_PARTNER_CODE });
            setState({ backendUser: userFromBackend, firebaseUser: user });
            Cookies.set('jwt', await user.getIdToken());
          } catch (error) {
            await deleteUser(user);
            handleError(error);
          }
          return unsubscribe();
        } else {
          loginOrSignUpAxaUser();
        }
      });
    }
  }, []);

  /**
   * Vitaly integration
   *
   */
  useState(() => {
    if (isVitalyIntegration) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user !== null) {
          try {
            const userFromBackend = await createUser({ partnerCode: VITALY_PARTNER_CODE });
            setState({ backendUser: userFromBackend, firebaseUser: user });
            Cookies.set('jwt', await user.getIdToken());
          } catch (error) {
            handleVitalyError(error);
          }
          return unsubscribe();
        }
      });

      /**
       * Listen for window messages from parent
       */
      window.addEventListener('message', vitalyMessagesListener);

      return () => window.removeEventListener('message', vitalyMessagesListener);
    }
  }, []);

  //! check if partner is valid
  //! check if user is valid
  //! if not valid: throw error, and error page
  //? for testing: http://localhost:3001/home?partner=test&user=pepe
  useState(() => {
    if (partnerMode) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        DEBUG_MODE && console.log('AuthContext: ', user);
        const partnerCode = parameters.get('partnerCode') ?? parameters.get('partner');

        if (user !== null) {
          try {
            const userFromBackend = await createUser({ partnerCode });
            setState({ backendUser: userFromBackend, firebaseUser: user });
            Cookies.set('jwt', await user.getIdToken());
          } catch (error) {
            await deleteUser(user);
            handleError(error);
          }
          return unsubscribe();
        } else {
          const user = parameters.get('user');
          const email = parameters.get('email') || `${user}@${partnerCode}.com`;
          const password = `000000${user}`;

          setIsLoadingLogin(isEmailPartner(partnerCode));
          const authPromise = await registerOrLoginPartner(email, password, user, partnerCode);
          !authPromise && setIsLoadingLogin(false);
        }
      });
    }
  }, []);

  const fetchBackendUser = async () => {
    const unsubscribe = onAuthStateChanged(auth, async () => {
      const userFromBackend = await getMyUser();
      setState({ ...state, backendUser: userFromBackend });
      setIsLoadingLogin(false);
      DEBUG_MODE && console.log('fetchBackendUser', { userFromBackend });
      return unsubscribe();
    });
  };

  const updateBackendUser = async (newUser) => {
    DEBUG_MODE && console.log('updateBackendUser', { newUser });
    setState({ ...state, backendUser: newUser });
  };

  if (state === undefined || (isVitalyIntegration && !state?.backendUser)) {
    return <Loading />;
  } else {
    const value = {
      backendUser: state.backendUser,
      firebaseUser: state.firebaseUser,
      theme: state.theme,
      isLoadingLogin,
      fetchBackendUser,
      updateBackendUser,
      setIsPointsModalVisible,
      isPointsModalVisible,
      langIsLoading,
      setLangIsLoading,
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
}
