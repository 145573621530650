'use client';

import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { MainProvider } from 'src/lib/providers/MainProvider';

const App = dynamic(() => import('src/App'), { ssr: false });
const Cookies = dynamic(() => import('src/components/Cookies'), { ssr: false });

export function ClientOnly() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  return (
    <MainProvider>
      <App />
      <Cookies />
    </MainProvider>
  );
}
