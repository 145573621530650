import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../components/Loading';
import { LANGS } from '../constants';
import { isIOS } from '../components/app/helpers';

export const VideoContext = createContext();

const States = { WAITING: 1, DOWNLOADING: 2, DELAYING: 3, CANCELLING: 4 };
let state = States.WAITING;
let preparations = [];
let currentDownload;

export function VideoProvider({ children }) {
  const [database, setDatabase] = useState(undefined);
  const [audios, setAudios] = useState();

  useEffect(() => {
    initializeDatabase();
  }, []);

  useEffect(() => {
    downloadPreparations();
  }, [preparations]);

  const sharedWithinContext = { prepareVideos, cancelPreparation, getVideo, setAudios, audios, getAudioCorrections };

  if (!database) {
    return <Loading />;
  }

  return (
    <>
      <VideoContext.Provider value={sharedWithinContext}>{children}</VideoContext.Provider>
    </>
  );

  function initializeDatabase() {
    const request = indexedDB.open('Fisify', 1);

    request.onupgradeneeded = (e) => e.target.result.createObjectStore('videos');
    request.onsuccess = async (e) => {
      setDatabase(e.target.result);

      // Limpia cualquier blob almacenado en el IndexedDB
      await cleanUpBlobs(e.target.result);
    };
  }

  async function cleanUpBlobs(db) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction('videos', 'readwrite');
      const store = transaction.objectStore('videos');

      // Abrir un cursor para iterar sobre los registros
      const request = store.openCursor();

      request.onsuccess = (e) => {
        const cursor = e.target.result;

        if (cursor) {
          // Revisa si el registro actual es un blob
          if (cursor.value && cursor.value instanceof Blob) {
            // Si es un blob, borra ese registro
            cursor.delete();
          }

          // Continuar con el siguiente registro
          cursor.continue();
        } else {
          // No hay más registros que verificar
          resolve();
        }
      };

      request.onerror = (e) => {
        console.error('Error during cleanup:', e);
        reject(e);
      };

      transaction.oncomplete = () => {
        console.log('Cleanup completed');
      };

      transaction.onerror = (e) => {
        console.error('Transaction error during cleanup:', e);
      };
    });
  }

  function getFromDatabase(url) {
    return new Promise((resolve) => {
      const request = database.transaction('videos').objectStore('videos')?.get(url);
      if (!request) return resolve(null);
      request.onsuccess = (e) => {
        const result = e.target.result;
        if (result) {
          const blob = new Blob([result.buffer], { type: result.type });
          resolve(blob);
        } else {
          resolve(null);
        }
      };
    });
  }

  async function saveInDatabase(url, blob) {
    const buffer = await blob.arrayBuffer();

    const dataToStore = {
      buffer: buffer,
      type: blob.type,
    };

    return new Promise(
      (resolve) =>
        (database.transaction('videos', 'readwrite').objectStore('videos').add(dataToStore, url).onsuccess = (e) =>
          resolve(e.target.result)),
    );
  }

  async function prepareVideos(preparationsUrls, onProgress) {
    const urls = preparationsUrls?.map((url) => (isIOS() ? url.replace('.webm', '.mp4') : url));

    if (preparations.length > 0) {
      state = States.DELAYING;
    }
    let resolver;
    let rejecter;
    const promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    preparations.unshift({ promise, resolver, rejecter, urls, onProgress });

    if (state === States.WAITING) {
      state = States.DOWNLOADING;
      downloadPreparations();
    }

    return promise;
  }

  async function cancelPreparation() {
    if (preparations.length > 0) {
      const firstPromise = preparations[0].promise;
      preparations.shift();

      state = States.CANCELLING;
      await firstPromise;
    }
    return;
  }

  async function downloadPreparations() {
    while (preparations.length > 0) {
      const preparation = preparations[0];
      const url = preparation.urls[0];

      if (!(await getFromDatabase(url))) {
        await download(url, preparation);
      }
      if (state === States.DOWNLOADING) {
        preparation.onProgress?.(100);
        preparation.urls.shift();
        if (preparation.urls.length === 0) {
          preparations.shift();
          preparation.resolver();
        }
      } else if (state === States.DELAYING) {
        state = States.DOWNLOADING;
      } else if (state === States.CANCELLING) {
        preparation.rejecter();
        state = States.DOWNLOADING;
      }
    }
    state = States.WAITING;
  }

  async function getAudio(url) {
    let response = '';

    if (url.includes('description/.mp3')) {
      try {
        response = await axios.get(url, { responseType: 'blob' });
      } catch (error) {
        const secondTryUrl = url.replace('description/.mp3', 'description.mp3');
        try {
          response = await axios.get(secondTryUrl, { responseType: 'blob' });
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (!url.includes('description/.mp3')) {
      response = await axios.get(url, { responseType: 'blob' });
    }
    return response.data;
  }

  function keyToMP3(key, lang) {
    const API_URL = 'https://audio-correcciones.s3.eu-west-3.amazonaws.com';
    const mp3Url = `${API_URL}/${lang}/${key.replaceAll('.', '/')}`;
    const extension = '.mp3';

    return key.includes('description') ? `${mp3Url}/${extension}` : `${mp3Url}${extension}`;
  }

  async function getAudioCorrections(exercise, lang = LANGS.SPANISH) {
    const { corrections } = exercise;
    const mappedCorrections = [];

    for (const correction of corrections) {
      try {
        const audio = await getAudio(keyToMP3(correction, lang));
        if (audio) {
          mappedCorrections.push({ key: correction, blob: audio });
        }
      } catch (error) {
        console.log(error);
      }
    }

    return mappedCorrections;
  }

  async function getVideo(url, onProgress) {
    if (preparations.length > 0) {
      if (preparations[0].urls[0] === url) {
        // ? Currently downloading this video
        const currentCallback = preparations[0].onProgress;
        preparations[0].onProgress = (progress) => {
          currentCallback?.(progress);
          // onProgress?.(progress);
        };
        await currentDownload;
        preparations[0].onProgress = currentCallback;
      } else {
        if (!(await getFromDatabase(url))) {
          await prepareVideos([url], onProgress);
        }
      }
    }

    const video = await getFromDatabase(url);
    onProgress?.(100);

    return video;
  }

  async function download(url, preparation) {
    const controller = new AbortController();
    const signal = controller.signal;
    const response = await fetch(url, { signal });
    const reader = response.body.getReader();

    const contentLength = +response.headers.get('Content-Length');
    const array = new Uint8Array(contentLength);
    let receivedBytes = 0;

    let resolveCurrentDownload;
    currentDownload = new Promise((resolve) => (resolveCurrentDownload = resolve));

    let runLoop = true;
    while (runLoop) {
      const { done, value } = await reader.read();

      if (done) {
        runLoop = false;
        break;
      }

      array.set(value, receivedBytes);
      receivedBytes += value.length;

      const percentage = Math.floor((receivedBytes / contentLength) * 100);
      preparation.onProgress?.(percentage);

      if (state !== States.DOWNLOADING) {
        controller.abort();
        runLoop = false;
      }
    }

    if (receivedBytes == contentLength) {
      const blob = new Blob([array.buffer], { type: 'video/mp4' });

      await saveInDatabase(url, blob);

      resolveCurrentDownload();

      return blob;
    }

    window?.onProgress?.(100);

    return null;
  }
}
