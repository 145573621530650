import styles from '../styles/main.module.scss';
import React from 'react';

const ErrorHandler = () => {
  return (
    <div className={styles.errorHandlerContainer}>
      <img src='/img/errorhandler.webp' />
    </div>
  );
};

export default ErrorHandler;
